<template>
  <div>
    <ca-card
      v-if="isAdmin"
      class="mb-3"
    >
      <template #header>
        <h4 class="mb-0">
          Zahlungsdaten
        </h4>
      </template>
      <template #body>
        <form
          novalidate
          @submit.prevent="save"
        >
          <basic-input
            id="konto"
            v-model="user.konto"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Konto"
            :horizontal="true"
            label="Konto"
          />
          <basic-input
            id="iban"
            v-model="user.iban"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="IBAN"
            :horizontal="true"
            label="IBAN"
          />
          <basic-input
            id="blz"
            v-model="user.blz"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="BLZ"
            :horizontal="true"
            label="BLZ"
          />
          <basic-input
            id="bic"
            v-model="user.bic"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="BIC"
            :horizontal="true"
            label="BIC"
          />
          <basic-input
            id="bank"
            v-model="user.bank"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Bank"
            :horizontal="true"
            label="Bank"
          />

          <basic-input
            id="bankBezeichnung"
            v-model="user.bankBezeichnung"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Bezeichnung"
            :horizontal="true"
            label="Bezeichnung"
          />
          <basic-input
            id="kontoinhaber"
            v-model="user.kontoinhaber"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Kontoinhaber"
            :horizontal="true"
            label="Kontoinhaber"
          />
          <basic-datepicker
            id="sepaMandat"
            v-model="user.sepaMandat"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="SEPA-Mandat"
            :horizontal="true"
            label="SEPA-Mandat"
          />
          <basic-input
            id="sepaMandatsreferenz"
            v-model="user.sepaMandatsreferenz"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="SEPA-Mandatsreferenz"
            :horizontal="true"
            label="SEPA-Mandatsreferenz"
          />
          <basic-datepicker
            id="sepaZuletztVerwendetAm"
            v-model="user.sepaZuletztVerwendetAm"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="zuletzt verwendet am"
            :horizontal="true"
            label="zuletzt verwendet am"
          />
          <basic-select
            id="lastschriftverfahren"
            v-model="user.lastschriftverfahren"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Lastschriftverfahren"
            :horizontal="true"
            label="Lastschriftverfahren"
            :options="humanLastschriftverfahrenOptions"
          />
        </form>
      </template>
    </ca-card>
  </div>
</template>
<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import CaCard from '@/components/Card'
import formatDate from '../../../filters/formatDate.js'
import lastschriftverfahren from '@/resources/enums/lastschriftverfahren.js'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
export default {
  components: {
    BasicInput,
    BasicDatepicker,
    CaCard,
    BasicSelect
  },
  filters: {
    formatDate
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedProduct: null,
    showSubscriptionModal: false,
    productAlertMessage: null,
    client: null,
    showProducts: [],
    lastschriftverfahren
  }),
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    isAdmin () {
      return this.me && this.me.role === 10
    },
    humanLastschriftverfahrenOptions () {
      const options = Object.values(this.lastschriftverfahren).map(lastschriftverfahren => ({ label: lastschriftverfahren.human, value: lastschriftverfahren.api }))
      return options
    }
  },
  async created () {
    this.client = (await this.$store.dispatch('clients/find')).data[0]
  }
}
</script>
