module.exports = {
  VM_ONLY: {
    api: 'VM_ONLY',
    human: 'E-Mails nur an Vermittler',
    sendToVM: true,
    sendToCustomer: false,
    emailHint: 'Hinweis: Diese E-Mail wurde lediglich an den Vermittler versendet, und muss ggf. an den Kunden weitergeleitet werden.'
  },
  VM_CUSTOMER: {
    api: 'VM_CUSTOMER',
    human: 'E-Mails auch an Kunden',
    sendToVM: true,
    sendToCustomer: true,
    emailHint: 'Hinweis: Dem Kunden wurde ebenfalls eine entsprechende E-Mail zugesendet, wenn eine E-Mail-Adresse des Kunden gespeichert wurde.'
  },
  CUSTOMER_ONLY: {
    api: 'CUSTOMER_ONLY',
    human: 'E-Mails nur an Kunden',
    sendToVM: false,
    sendToCustomer: true
  }
}
