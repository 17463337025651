<template>
  <div>
    <ca-card
      v-if="$can('editAllUsersRoles')"
      class="mb-3"
    >
      <template #header>
        <h4 class="mb-0">
          Admin
        </h4>
      </template>
      <template #body>
        <div
          v-if="user.pending"
          class="row align-items-center mb-3"
        >
          <div class="col-auto pr-0">
            <i class="fad fa-info-circle fa-2x text-primary" />
          </div>
          <div class="col">
            Dieser Nutzer hat seinen Account bisher nicht bestätigt.
          </div>
        </div>
        <form
          novalidate
          @submit.prevent="save"
        >
          <basic-select
            v-model="user.account.roleId"
            data-test="rolle"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Rolle"
            :options="filteredRoles"
          />

          <basic-switch
            v-model="user.active"
            class="mb-0"
            data-test="nutzer"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Nutzer"
          >
            <template #label>
              Nutzer Aktiv / Inaktiv
            </template>
          </basic-switch>
          <template v-if="isMakler">
            <div
              v-if="vermittlerAmsStatus"
              class="row"
            >
              <p class="col-12 col-md-4 mb-0">
                Vermittlerstatus
              </p>
              <p class="col-12 col-md-8 mb-0">
                {{ vermittlerAmsStatus }}
              </p>
            </div>
            <basic-switch
              v-model="user.canSetUntervermittler"
              class="mb-0"
              data-test="nutzer"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              :horizontal="true"
              label="Untervermittlernummer"
            >
              <template #label>
                Untervermittlernummer
              </template>
            </basic-switch>
          </template>

          <!-- <basic-checkbox
            v-model="user.features.canCreateOrder"
            data-test="akg"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            class="my-3"
            label="Antrag KFZ Garantie"
          /> -->

          <!-- <basic-checkbox
            v-model="user.features.canSeePlusNews"
            data-test="bfan"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            class="my-3"
            label="Berechtigt für alle News"
          />

          <basic-checkbox
            v-model="user.features.canCreateKaufschutzOrder"
            data-test="ak"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            class="my-3"
            label="Antrag Kaufschutz"
          /> -->

          <!-- <basic-input
            v-model="user.traderId"
            data-test="handlernr"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            required
            placeholder="Benutzername"
            label="Benutzername"
          /> -->

          <!-- <basic-select
            v-model="user.payment"
            data-test="zahlart"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Zahlart"
          >
            <option>Rechnung</option>
            <option>Lastschrift</option>
          </basic-select> -->
        </form>
      </template>
    </ca-card>
  </div>
</template>
<script>
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicSwitch from '@/components/BaseComponents/BasicSwitch'
import CaCard from '@/components/Card'
import formatDate from '../../../filters/formatDate.js'
import roles from '@/resources/enums/roles.js'
export default {
  components: {
    BasicSelect,
    BasicSwitch,
    CaCard
  },
  filters: {
    formatDate
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedProduct: null,
    showSubscriptionModal: false,
    productAlertMessage: null,
    client: null,
    showProducts: []
  }),
  computed: {
    filteredRoles () {
      const loggedInAccount = this.$store.getters['auth/user']
      if (!loggedInAccount.role.availableRoleIds) return []
      return Object.values(roles)
        .filter(role => loggedInAccount.role.availableRoleIds.includes(role.api))
        .map(role => {
          return { label: role.human, value: role.api }
        })
    },
    me () {
      return this.$store.getters['auth/user'].user
    },
    agreements () {
      const agreements = this.user.agreements || []
      return agreements.filter(agreement => agreement.name === 'Informationspflicht_Helvetia_Venture_Fund')
    },
    totpConfigured () {
      return this.user.twoFactor && this.user.twoFactor.TOTP && !!this.user.twoFactor.TOTP.secret
    },
    u2fConfigured () {
      return this.user.twoFactor && this.user.twoFactor.U2F && !!this.user.twoFactor.U2F.publicKey
    },
    smsConfigured () {
      return this.user.twoFactor && this.user.twoFactor.SMS && !!this.user.twoFactor.SMS.secret
    },

    totpAvailable () {
      return !!this.client && !!this.client.twoFactor && !!this.client.twoFactor.TOTP && !!this.client.twoFactor.TOTP.enabled
    },
    u2fAvailable () {
      return !!this.client && !!this.client.twoFactor && !!this.client.twoFactor.U2F && !!this.client.twoFactor.U2F.enabled
    },
    smsAvailable () {
      return !!this.client && !!this.client.twoFactor && !!this.client.twoFactor.SMS && !!this.client.twoFactor.SMS.enabled
    },
    twoFactorAvailable () {
      return this.totpAvailable || this.u2fAvailable || this.smsAvailable
    },
    isOwnUser () {
      return this.me._id === this.user._id
    },
    isMakler () {
      return this.user.account.roleId === roles.makler.api
    },
    vermittlerAmsStatus () {
      if (!this.isMakler) return null
      return this.user.active ? 'VERMITTLER' : 'EX-VERMITTLER'
    }
  },
  async created () {
    this.client = (await this.$store.dispatch('clients/find')).data[0]
  }
}
</script>
