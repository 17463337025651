<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Partnerdaten
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent
      >
        <div class="row mb-0">
          <p class="col-12 col-md-4 mb-0">
            Vermittlernummer
          </p>
          <p class="col-12 col-md-8 mb-0">
            {{ user.agentNumber }}
          </p>
        </div>
        <basic-input
          id="traderId"
          v-model="user.traderId"
          :disabled="disableInputsFields"
          data-test="owner-form-firstname"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Benutzername"
          :horizontal="true"
          label="Benutzername"
          required
        />

        <basic-input
          id="firstname"
          v-model="user.firstname"
          :disabled="disableInputsFields"
          vias="SUCHNAM"
          data-test="owner-form-firstname"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vorname (Suchname)"
          :horizontal="true"
          label="Vorname (Suchname)"
          required
        />

        <basic-input
          id="lastname"
          v-model="user.lastname"
          :disabled="disableInputsFields"
          vias="NAM"
          data-test="owner-form-lastname"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Nachname (Name 1)"
          :horizontal="true"
          label="Nachname (Name 1)"
          required
        />

        <basic-input
          id="additional"
          v-model="user.name2"
          :disabled="disableInputsFields"
          data-test="owner-form-additional"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Name 2"
          vias="NA2"
          :horizontal="true"
          label="Name 2"
        />
        <basic-input
          id="additional2"
          v-model="user.name3"
          :disabled="disableInputsFields"
          data-test="owner-form-additional2"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Name 3"
          vias="NA2"
          :horizontal="true"
          label="Name 3"
        />

        <basic-input
          id="street"
          v-model="user.street"
          :disabled="disableInputsFields"
          visa="STR"
          data-test="owner-form-street"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Straße, Nr."
          :horizontal="true"
          label="Straße, Nr."
        />

        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <basic-typeahead
              id="city-zip"
              v-model="user.zip"
              :disabled="disableInputsFields"
              vias="PLZ"
              data-test="owner-form-city-zip"
              name="PLZ"
              auto-select-first
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="PLZ"
              :query="searchCityByZip"
              :result-formatter="cityResultFormatter"
              :input-formatter="zipInputFormatter"
              :horizontal="true"
              label="PLZ / Ort"
              :margin="false"
              @hit="setCity"
            />
          </div>
          <div class="col-12 col-md-6">
            <basic-typeahead
              id="city-name"
              v-model="user.town"
              :disabled="disableInputsFields"
              vias="ORT"
              data-test="owner-form-city-name"
              name="Stadt"
              auto-select-first
              :query="searchCityByName"
              :result-formatter="cityResultFormatter"
              :input-formatter="cityInputFormatter"
              autocomplete="off"
              :margin="false"
              placeholder="Ort"
              @hit="setCity"
            />
          </div>
        </div>

        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <basic-typeahead
              id="country-char"
              v-model="user.country"
              :disabled="disableInputsFields"
              data-test="owner-form-country-char"
              name="Land"
              vias="NAT"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="Land"
              :horizontal="true"
              label="Land"
              :margin="false"
              :query="searchCountry"
              :result-formatter="countryResultFormatter"
              @hit="setCountry"
            />
            {{ user.country }}
          </div>
        </div>
        <basic-tel-input
          id="mobile"
          v-model="user.mobile"
          :disabled="disableInputsFields"
          vias="MOBIL_PRIVAT"
          data-test="owner-form-mobile"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Mobiltelefonnummer"
        />

        <basic-tel-input
          id="tel"
          v-model="user.telephone"
          :disabled="disableInputsFields"
          data-test="owner-form-tef"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Telefon 1"
        />

        <!-- <basic-tel-input
          id="tel2"
          v-model="user.telephone2"
          :disabled="disableInputsFields"
          data-test="owner-form-tef"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Telefon 2"
        /> -->

        <basic-tel-input
          id="fax"
          v-model="user.telefax"
          :disabled="disableInputsFields"
          data-test="owner-form-fax"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Telefax"
        />

        <basic-input
          id="email"
          v-model="user.email"
          :disabled="disableInputsFields"
          vias="MAIL"
          required
          data-test="owner-form-email"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="E-Mail"
          :horizontal="true"
          label="E-Mail"
          type="email"
        />

        <!-- <basic-datepicker
          id="birthday"
          v-model="user.birthday"
          :disabled="disableInputsFields"
          data-test="owner-form-birthday"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Geburtstag"
          :horizontal="true"
          label="Geburtstag"
        /> -->

        <!-- <basic-select
          id="gender"
          v-model="user.gender"
          :disabled="disableInputsFields"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Geschlecht"
          :horizontal="true"
          label="Geschlecht"
          :options="humanGenderOptions"
        /> -->
        <!-- <basic-select
          id="class"
          v-model="user.class"
          :disabled="disableInputsFields"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Klasse"
          :horizontal="true"
          label="Klasse"
          :options=""
        /> -->
        <basic-select
          id="status"
          v-model="user.status"
          :disabled="disableInputsFields"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Status"
          :horizontal="true"
          label="Status"
          :options="humanStatusOptions"
        />
        <!-- Kein Feldinhalt vorhanden -->
        <!-- <basic-select
          id="designation1"
          v-model="user.designation1"
          :disabled="disableInputsFields"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Bezeichnung 1"
          :horizontal="true"
          label="Bezeichnung 1"
          :options="humanDesignationOneOptions"
        /> -->
        <!-- <basic-select
          id="designation1"
          v-model="user.designation2"
          :disabled="disableInputsFields"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Bezeichnung 2"
          :horizontal="true"
          label="Bezeichnung 2"
          :options=""
        /> -->
        <basic-datepicker
          id="eintritt"
          v-model="user.eintritt"
          :disabled="disableInputsFields"
          data-test="owner-form-eintritt"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Eintritt"
          :horizontal="true"
          label="Eintritt"
        />

        <basic-datepicker
          id="austritt"
          v-model="user.austritt"
          :disabled="disableInputsFields"
          data-test="owner-form-austritt"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Austritt"
          :horizontal="true"
          label="Austritt"
        />

        <!-- <basic-input
          id="steuerNummer"
          v-model="user.steuerNummer"
          :disabled="disableInputsFields"
          data-test="owner-form-email"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Steuer-Nr"
          :horizontal="true"
          label="Steuer-Nr"
        /> -->

        <!-- <basic-input
          id="ustIdNr"
          v-model="user.ustIdNr"
          :disabled="disableInputsFields"
          data-test="owner-form-email"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="UST-IDNr"
          :horizontal="true"
          label="UST-IDNr"
        /> -->
        <basic-input
          id="registernummer"
          v-model="user.registernummer"
          :disabled="disableInputsFields"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Registernummer"
          :horizontal="true"
          label="Registernummer"
        />
        <basic-input
          id="agentNumberExtern"
          v-model="user.agentNumberExtern"
          :disabled="disableInputsFields"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vermittlernummer Extern"
          :horizontal="true"
          label="Vermittlernummer Extern"
        />
        <!-- <basic-input
          id="ustIdNr"
          v-model="user.zustIHK"
          :disabled="disableInputsFields"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Zust.IHK (Ort)"
          :horizontal="true"
          label="Zust.IHK (Ort)"
        /> -->
        <BasicInput
          id="mailInvoice"
          v-model="user.emailInvoice"
          :disabled="disableInputsFields"
          label="Mailadresse für Abrechnungsversand"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          type="email"
          placeholder="E-Mail"
        />
        <BasicInput
          id="emailPolicyCopy"
          v-model="user.emailPolicyCopy"
          :disabled="disableInputsFields"
          label="Mailadresse Policenversand (Kopie)"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          type="email"
          placeholder="E-Mail"
        />
        <BasicTypeahead
          id="agentGroupId"
          v-model="user.agentGroup"
          :disabled="disableInputsFields"
          label="Vermittlergruppe"
          :query="searchAgentGroup"
          :result-formatter="agentGroupResultFormatter"
          :input-formatter="agentGroupInputFormatter"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          placeholder="Nummer, Name"
          @hit="setAgentGroup"
        />
        <!-- BasicIbanInput setzt Iban, bic, blz und bankname -->
        <BasicIbanInput
          v-model="user"
          :disabled="disableInputsFields"
        />
        <BasicInput
          v-model="user.kontoinhaber"
          :disabled="disableInputsFields"
          label="Kontoinhaber"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          placeholder="Kontoinhaber"
        />
        <BasicSelect
          v-model="user.emailReceiverOption"
          :disabled="disableInputsFields"
          label="Mailversandoptionen"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          :options="humanMailReceiverOptions"
          placeholder="Bitte auswählen"
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead'
import BasicTelInput from '@/components/BaseComponents/BasicTelInput'
import CaCard from '@/components/Card'
import TitleSelectMixin from '@/components/Forms/title-select-mixin.js'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import status from '@/resources/enums/userStatus.js'
import geschlecht from '@/resources/enums/geschlecht.js'
import BasicIbanInput from '@/components/BaseComponents/BasicIbanInput.vue'
import mailReceiverOptions from '@/resources/enums/mailReceiverOptions'

export default {
  inject: ['$validator'],
  name: 'HalterForm',
  components: {
    BasicInput,
    CaCard,
    BasicTypeahead,
    BasicTelInput,
    BasicDatepicker,
    BasicSelect,
    BasicIbanInput
  },
  mixins: [TitleSelectMixin],
  props: {
    user: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      extend: true,
      status,
      geschlecht,
      agentGroup: null,
      iban: { iban: null }
    }
  },
  computed: {
    disableInputsFields () {
      return !this.$can('editAllUsers')
    },
    humanStatusOptions () {
      const options = Object.values(this.status).map(status => ({ label: status.human, value: status.api }))
      return options
    },
    humanGenderOptions () {
      const options = Object.values(this.geschlecht).map(geschlecht => ({ label: geschlecht.human, value: geschlecht.api }))
      return options
    },
    humanMailReceiverOptions () {
      return Object.values(mailReceiverOptions).map(option => ({ label: option.human, value: option.api }))
    }
  },
  methods: {
    searchCityByZip (zip) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          zip: { $regex: '^' + zip, $options: 'i' }
        }
      })
    },
    searchCityByName (name) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          name: { $regex: '^' + name, $options: 'i' }
        }
      })
    },
    cityResultFormatter (city) {
      return `${city.zip} ${city.name}`
    },
    setCity (city) {
      if (city.zip) {
        this.user.zip = city.zip
      }
      if (city.name) {
        this.user.town = city.name
      }
    },
    zipInputFormatter (city) {
      if (typeof city === 'object') {
        return city.zip
      }
      return city
    },
    cityInputFormatter (city) {
      if (typeof city === 'object') {
        return city.name
      }
      return city
    },
    searchCountry (char) {
      return this.$store.dispatch('countries/find', {
        query: {
          $limit: 10, char: char.toUpperCase()
        }
      })
    },
    countryResultFormatter (country) {
      return `${country.char} ${country.name}`
    },
    setCountry (country) {
      this.user.country = country.char
    },
    searchAgentGroup (input) {
      return this.$store.dispatch('agent-groups/find', {
        query: {
          $limit: 50,
          $or: [
            { number: { $regex: input, $options: 'i' } },
            { name: { $regex: input, $options: 'i' } },
            { name1: { $regex: input, $options: 'i' } }
          ]
        }
      })
    },
    agentGroupInputFormatter (agentGroup) {
      if (typeof agentGroup !== 'object') return agentGroup
      return agentGroup.number
    },
    agentGroupResultFormatter (agentGroup) {
      if (typeof agentGroup !== 'object') return agentGroup
      return [agentGroup.number, agentGroup.name, agentGroup.name1].join(' ')
    },
    setAgentGroup (agentGroup) {
      this.$set(this.user, 'agentGroup', agentGroup.number)
    }
  }
}
</script>
